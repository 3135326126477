<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         @on-visible-change="onChangeVisible"
         width="600"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>问题类型：</p>
          <Cascader :data="questTypeArr"
                    v-model="questType"
                    style="width:100%;"></Cascader>
        </li>
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>发生地点：</p>
          <Input v-model="site"
                 readonly
                 placeholder="发生地点">
          <Icon custom="i-icon icon-weizhishi"
                size="20"
                color="#25bb96"
                class="cursor-pointer"
                slot="suffix"
                @click="onClickSite" />
          </Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title">设施名称：</p>
          <Select v-model="facilityType"
                  placeholder="设施类型"
                  @on-change="onChangeFacilityType"
                  clearable
                  class="half">
            <Option :value="item.id"
                    v-for="item in facilityTypeArr"
                    :key="item.id">{{item.name}}</Option>
          </Select>
          <MyTreeSelect :value="facility"
                        placeholder="设施名称"
                        clearable
                        :data="facilityArr"
                        search
                        @onSelectChange="onChangeFacility"
                        class="half">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>指派对象：</p>
          <MyTreeSelect :value="assign"
                        placeholder="指派对象"
                        clearable
                        :data="userTreeArr"
                        search
                        :disabled="platformAssign || autoAssign"
                        @onSelectChange="onChangeAssign"
                        class="half">
          </MyTreeSelect>
          <div class="half">
            <Checkbox label="pingtai"
                      class="form-ul-li-checkbox"
                      :disabled="autoAssign"
                      v-model="platformAssign"
                      @on-change="onChangePlatformAssign">由平台指派</Checkbox>
            <Checkbox label="auto"
                      class="form-ul-li-checkbox"
                      v-model="autoAssign"
                      :disabled="platformAssign"
                      @on-change="onChangeAutoAssign">自动指派</Checkbox>
          </div>
        </li>
        <li class="form-ul-li">
          <p class="li-title"></p>
          <span class="file-tip">指派对象与由平台指派三选一</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>紧急程度：</p>
          <Select v-model="level"
                  placeholder="紧急程度"
                  style="width: 100%;"
                  @on-change="onChangeWorkOrderLevel">
            <Option v-for="item in levelArr"
                    :value="item.casesetLevelId"
                    :key="item.casesetLevelId">{{item.name}}</Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>限时：</p>
          <Input v-model="time"
                 placeholder="限时"
                 readonly
                 class="input-right-5"></Input>
          <span class="li-unit">分钟</span>
        </li>
        <li class="form-ul-li">
          <p class="li-title">抄送人：</p>
          <MyTreeSelect :value="CC"
                        placeholder="抄送人"
                        clearable
                        :data="userTreeArr"
                        search
                        checked
                        @onCheckChange="onChangeCC"
                        class="half">
          </MyTreeSelect>
          <div class="half">
            <Checkbox class="form-ul-li-checkbox"
                      v-model="sendSms">短信推送</Checkbox>
          </div>
        </li>
        <!-- <li class="form-ul-li">
          <p class="li-title">日期：</p>
          <DatePicker :value='date'
                      type="date"
                      style="width: 100%"
                      @on-change="onChangeDate"></DatePicker>
        </li> -->
        <li class="form-ul-li">
          <p class="li-title">问题描述：</p>
          <Input v-model="desc"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="问题描述" />
        </li>
        <li class="form-ul-li">
          <p class="li-title">文件多媒体：</p>
          <ul class="file-img">
            <li v-for="item,index in pictureArr"
                :key="index">
              <el-image :src="item"
                        fit="cover"
                        :preview-src-list="pictureArr"
                        class="w-full h-full">
              </el-image>
              <Icon type="md-close-circle"
                    size="24"
                    color="#fa5357"
                    class="file-img-deleteIcon"
                    @click.stop="onClickDeletePicture(index)" />
            </li>
            <UploadFile @uploadSuccess="uploadPicture"
                        multiple
                        v-show="pictureArr.length<9">
              <li>
                <Icon custom="i-icon icon-tupianshangchuan"
                      size="40"
                      color="#B5B5B5" />
              </li>
            </UploadFile>
            <!-- <li>
                  <Icon custom="i-icon icon-yuyin"
                        size="40"
                        color="#B5B5B5" />
                </li> -->
            <!-- <li>
              <Icon custom="i-icon icon-shipin"
                    size="40"
                    color="#B5B5B5" />
            </li> -->
          </ul>
        </li>
        <!-- <li class="form-ul-li">
          <p class="li-title"></p>
          <div class="file-tip">
            图片：支持格式为png、jpg、jpeg、bmp，最多不能超过9张，最大不能超过1M。
          </div>
        </li> -->
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
    <PositionModal v-model="positionVisible"
                   :lng="lng"
                   :lat="lat"
                   @onClickConfirm="onChangeSite" />
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MyTreeSelect from '../../../../common/MyTreeSelect'
import UploadFile from '@/components/common/UploadFile'
import PositionModal from '@/components/product/main/template/PositionModal'
export default {
  components: {
    MyTreeSelect,
    UploadFile,
    PositionModal
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '巡查工单'
    },
    visible: Boolean,
    dataId: String
  },
  data () {
    return {
      loading: false,
      questType: [],
      facilityType: '',
      facilityId: '',
      facility: '',
      facilityArr: [],
      assignId: '',
      assign: '',
      platformAssign: false,
      autoAssign: false,
      level: '',
      time: '',
      CCId: '',
      CC: '',
      sendSms: false,
      desc: '',
      // date: '',
      pictureArr: [],
      // 选择地址
      positionVisible: false,
      lng: '',
      lat: '',
      site: '',
    }
  },
  watch: {
    dataId (newValue) {
      newValue && this.getDetail()
    }
  },
  computed: {
    ...mapGetters({
      'levelArr': 'getWorkOrderLevelAllList',
      'userTreeArr': 'getUserTreeList',
      'facilityTypeArr': 'getFacilityBigTypeAllList',
    }),
    questTypeArr () {
      let arr = this.$store.getters.getWorkOrderQuestTypeTreeList.map(item => {
        let obj = {
          value: item.id,
          label: item.name,
        }
        if (item.children && item.children.length != 0) {
          obj.children = item.children.map(items => {
            return {
              value: items.id,
              label: items.name
            }
          })
        }
        return obj
      })
      return arr
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateWorkOrderLevelAllList',
      'updateUserTreeList',
      'updateFacilityBigTypeAllList',
      'updateWorkOrderQuestTypeTreeList',
    ]),
    init () {
      // patrolCase已调用，防重复调用
      // this.updateWorkOrderLevelAllList()
      // this.updateWorkOrderQuestTypeTreeList()
      // this.updateUserTreeList()
      this.updateFacilityBigTypeAllList()
    },
    getDetail () {
      this.$store.dispatch('getPatrolCaseDetail', this.dataId).then(res => {
        for (const i in res) {
          this[i] = res[i]
        }
        console.log(res);
        if (res.picture) {
          this.pictureArr = res.picture.split(',').filter(item => item)
        }
        if (res.questBigType) {
          this.questType = [res.questBigType, res.questSmallType]
        }
        this.platformAssign = res.platformAssign == 1
        this.sendSms = res.sendSms == 1
      })
    },
    // 点击选择地址
    onClickSite () {
      this.positionVisible = true
    },
    // 修改地址
    onChangeSite (e) {
      this.site = e.address
      this.lng = e.lng
      this.lat = e.lat
    },
    onChangeFacilityType (value) {
      if (value) {
        this.$http.getBindObjectTreeList({ treetype: value }).then(res => {
          if (res.code === 200) {
            this.facilityArr = res.result
          }
        })
      } else {
        this.$http.getBindObjectTreeList({ treetype: 'facl' }).then(res => {
          if (res.code === 200) {
            this.facilityArr = res.result
          }
        })
      }
      this.facilityId = ''
      this.facility = ''
    },
    onChangeFacility (data) {
      if (data.type !== 'depart') {
        this.facilityId = data.id
        this.facility = data.name
      }
    },
    // 修改指派对象
    onChangeAssign (data) {
      if (data.type !== 'depart') {
        this.assignId = data.id
        this.assign = data.name
      }
    },
    // 修改平台指派
    onChangePlatformAssign (boo) {
      if (boo) {
        this.assignId = ''
        this.assign = ''
      }
    },
    // 自动指派
    onChangeAutoAssign (boo) {
      if (boo) {
        this.assignId = ''
        this.assign = ''
      }
    },
    onChangeWorkOrderLevel (i) {
      if (i) {
        this.time = this.levelArr[i - 1].time
      }
    },
    // 修改抄送人
    onChangeCC (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type !== 'depart') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      this.CCId = idArr.join()
      this.CC = valueArr.join()
    },
    // 上传照片
    uploadPicture (arr) {
      if (arr.length > 0) {
        this.pictureArr = this.pictureArr.concat(arr.map(item => item.url))
      }
    },
    // 删除照片
    onClickDeletePicture (index) {
      this.pictureArr.splice(index, 1)
    },
    // 选择日期
    onChangeDate (date) {
      this.date = date
    },
    onClickConfirm () {
      if (!this.questType.length) return this.$Message.info('请选择问题类型')
      if (!this.site) return this.$Message.info('请选择发生地点')
      if (!this.level) return this.$Message.info('请选择紧急程度')
      if (!this.time) return this.$Message.info('请输入限时分钟')
      if (this.autoAssign) {
        this.$http.automaticAssignPatrolCase({
          lng: this.lng,
          lat: this.lat,
        }).then(res => {
          let data = res.result
          if (!data.value) {
            this.automatic = false
            this.$Message.info('暂无指派人可用')
          } else {
            this.assignId = data.value
            this.assign = data.name
            this.assignPatrolCase()
          }
        })
      } else {
        this.assignPatrolCase()
      }
    },
    assignPatrolCase () {
      let params = {
        questBigType: this.questType[0],
        questSmallType: this.questType[1],
        site: this.site,
        lng: this.lng,
        lat: this.lat,
        facilityType: this.facilityType,
        facilityId: this.facilityId,
        facility: this.facility,
        assignId: this.assignId,
        assign: this.assign,
        platformAssign: this.platformAssign ? 1 : 0,
        level: this.level,
        time: this.time,
        CCId: this.CCId,
        CC: this.CC,
        sendSms: this.sendSms ? 1 : 0,
        desc: this.desc,
        picture: this.pictureArr.join()
      }
      if (this.dataId) params.id = this.dataId
      this.loading = true
      this.$store.dispatch('createOrEditPatrolCase', params).then(res => {
        this.loading = false
        this.$emit('onClickConfirm')
        this.$emit('onChange', false)
      }).catch(err => {
        this.loading = false
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    },
    resetData () {
      this.questType = []
      this.lng = ''
      this.lat = ''
      this.site = ''
      this.facilityType = ''
      this.onChangeFacilityType()
      this.assignId = ''
      this.assign = ''
      this.platformAssign = false
      this.level = ''
      this.time = ''
      this.CCId = ''
      this.CC = ''
      this.sendSms = false
      this.desc = ''
      // this.date = ''
      this.pictureArr = []
    }
  }
}
</script>

<style lang="scss" scoped>
.form-ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  li {
    width: 100%;
    .file-tip {
      padding: 0;
      line-height: 1;
    }
  }
  .half {
    width: 48%;
    margin-right: 2%;
  }
}
</style>