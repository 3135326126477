<template>
  <Modal :value="visible"
         :title="title"
         @on-visible-change="onChangeVisible"
         width="600"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>问题类型：</p>
          <Cascader :data="questTypeArr"
                    v-model="questType"
                    style="width:100%"></Cascader>
        </li>
        <!-- <li class="form-ul-li">
          <p class="li-title">日期：</p>
          <DatePicker :value='date'
                      type="date"
                      style="width: 100%"
                      @on-change="onChangeDate"></DatePicker>
        </li> -->
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '修改问题类型'
    },
    visible: Boolean,
    item: null
  },
  data () {
    return {
      questType: [],
      // date: ''
    }
  },
  watch: {
    item (newValue) {
      newValue && (this.questType = [this.item.caseIssueId, this.item.caseIssueChildId])
    }
  },
  computed: {
    questTypeArr () {
      let arr = this.$store.getters.getWorkOrderQuestTypeTreeList.map(item => {
        let obj = {
          value: item.id,
          label: item.name,
        }
        if (item.children && item.children.length != 0) {
          obj.children = item.children.map(items => {
            return {
              value: items.id,
              label: items.name
            }
          })
        }
        return obj
      })
      return arr
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateWorkOrderQuestTypeTreeList',
    ]),
    init () {
      // patrolCase已调用，防重复调用
      // this.updateWorkOrderQuestTypeTreeList()
    },
    // 选择日期
    onChangeDate (date) {
      this.date = date
    },
    // 确定按钮
    onClickConfirm () {
      if (!this.questType) return this.$Message.info('请选择问题类型');
      let params = {
        id: this.item.caseId,
        questBigType: this.questType[0],
        questSmallType: this.questType[1]
      }
      this.loading = true
      this.$store.dispatch('createOrEditPatrolCase', params).then(res => {
        this.loading = false
        this.$emit('onClickConfirm')
        this.$emit('onChange', false)
      }).catch(err => {
        this.loading = false
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:item', null)
        this.resetData()
      }
    },
    resetData () {
      this.questType = []
      // this.date = ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>