<template>
  <Modal :value="visible"
         :title="title"
         @on-visible-change="onChangeVisible"
         width="800"
         class="modal"
         class-name="vertical-center-modal">
    <div class="workorder">
      <ul class="workorder-left">
        <li v-for="(item,index) in workorderArr"
            :key="index"
            :class="{'current-workorder' : workorderIndex == index}"
            @click.stop="onClickWorkorder(index)">{{item.name}}</li>
      </ul>
      <div class="workorder-right">
        <template v-if="workorderIndex===0">
          <div class="title">
            <Icon custom="i-icon icon-jingshi"
                  size="16"
                  color='rgb(255, 149, 0)'></Icon>
            <p>配置工单等级与每个等级所对应限定处理时长</p>
          </div>
          <div class="grade modal-cont">
            <ul class="form-ul"
                v-for="(item,index) in gradeArr"
                :key="index">
              <li class="form-ul-li half">
                <p class="li-title">等级名称：</p>
                <Input v-model="item.name"
                       placeholder="等级名称"
                       maxlength="30"
                       v-stringLimit></Input>
              </li>
              <li class="form-ul-li half">
                <p class="li-title">限时时长：</p>
                <Input v-model="item.time"
                       placeholder="限时时长"
                       class="input-right-5"
                       maxlength="30"
                       v-intLimit></Input>
                <span class="li-unit">分钟</span>
              </li>
            </ul>
          </div>
        </template>
        <template v-if="workorderIndex===1">
          <div class="title">
            <Icon custom="i-icon icon-jingshi"
                  size="16"
                  color='rgb(255, 149, 0)'></Icon>
            <p>设置图片、视频、语音上传模式与大小</p>
          </div>
          <div class="reported">
            <div class="reported-item">
              <p class="item-title">图片设置</p>
              <div class="cont-switch">
                <p class="li-title">是否允许相册上传(此设置只针对移动端)：</p>
                <i-switch v-model="isImgYxxcsc" />
              </div>
              <div class="cont-input">
                <p class="li-title">照片大小(针对Web端)：</p>
                <Input v-model="imgsize"
                       placeholder=""
                       maxlength="30"
                       v-intLimit
                       class="input"></Input>
                <span>M</span>
              </div>
            </div>
            <div class="reported-item">
              <p class="item-title">视频设置</p>
              <div class="cont-switch">
                <p class="li-title">是否允许相册上传(此设置只针对移动端) ：</p>
                <i-switch v-model="isVideoYxxcsc" />
              </div>
              <div class="cont-input">
                <p class="li-title">视频时长(针对移动端)：</p>
                <Input v-model="videolength"
                       placeholder=""
                       maxlength="30"
                       v-intLimit
                       class="input"></Input>
                <span>S (平台限定最长时长60S)</span>
              </div>
              <div class="cont-input">
                <p class="li-title">视频大小(针对Web端)：</p>
                <Input v-model="videosize"
                       placeholder=""
                       maxlength="30"
                       v-intLimit
                       class="input"></Input>
                <span>M</span>
              </div>
            </div>
            <div class="reported-item">
              <p class="item-title">语音设置</p>
              <div class="cont-input">
                <p class="li-title">语音时长(针对移动端)：</p>
                <Input v-model="voicelength"
                       placeholder=""
                       maxlength="30"
                       v-intLimit
                       class="input"></Input>
                <span>S (平台限定最长时长60S)</span>
              </div>
              <div class="cont-input">
                <p class="li-title">音频大小(针对Web端)：</p>
                <Input v-model="voicesize"
                       placeholder=""
                       maxlength="30"
                       v-intLimit
                       class="input"></Input>
                <span>M</span>
              </div>
            </div>
          </div>
        </template>
        <template v-if="workorderIndex===2">
          <div class="quest flex">
            <div class="quest-big flex flex-column j-start">
              <ul class="flex-1 m-r-5">
                <li v-for="(item,index) in questTypeData"
                    :key="item.id"
                    @click.stop="onClickQuestType(index)"
                    :class="{'current-type':questTypeIndex == index}"
                    class="flex">
                  <div v-if="!item.isEdit"
                       class="flex-1 flex j-between a-center">
                    {{item.name}}
                    <div>
                      <Icon custom="i-icon icon-xiugai"
                            size="16"
                            class="m-r-5"
                            @click.stop="onClickEditBigType(item)" />
                      <Icon custom="i-icon icon-shanchu1"
                            size="16"
                            @click.stop="onClickDeleteBigType(item,index)" />
                    </div>
                  </div>
                  <div v-if="item.isEdit"
                       class="flex-1 flex j-between a-center"
                       @click.stop="">
                    <Input v-model="item.editName"
                           placeholder=""
                           maxlength="30"
                           v-stringLimit
                           style="width:100px;" />
                    <div>
                      <span @click.stop="onClickBigTypeConFirm(item,index)"
                            class="m-r-5">确定</span>
                      <span @click.stop="onClickBigTypeCancel(item,index)">取消</span>
                    </div>
                  </div>
                </li>
              </ul>
              <Button type="text"
                      style="padding-left:0;width:80px"
                      @click="onClickAddBigType">
                <Icon custom="i-icon icon-tianjiabiankuang"
                      size="16" />
                新增
              </Button>
            </div>
            <div class="flex-1 flex flex-column j-start">
              <Table ref="table"
                     :columns="columnsType"
                     :data="typeData"
                     border
                     stripe
                     height="442">
                <template slot-scope="{ row,index }"
                          slot="operation">
                  <Button @click.stop="onClickDeleteSmallType(row,index)"
                          class="tableOperaBtn"
                          type="text">删除</Button>
                </template>
              </Table>
              <Button type="text"
                      style="padding-left:0;width:80px"
                      @click.stop="onClickAddSmallType">
                <Icon custom="i-icon icon-tianjiabiankuang"
                      size="16" />
                新增
              </Button>
            </div>
          </div>
        </template>
        <template v-if="workorderIndex===3">
          <div class="title">
            <Icon custom="i-icon icon-jingshi"
                  size="16"
                  color='rgb(255, 149, 0)'></Icon>
            <p>设置逾期提醒(最大100分钟)</p>
          </div>
          <div class="overdue">
            <div class="cont-switch">
              <p class="li-title">是否开启逾期提醒</p>
              <i-switch v-model="isTimeoutAlarm" />
            </div>
            <div class="cont-input">
              <p class="li-title">提前：</p>
              <Input v-model="timeout"
                     placeholder=""
                     maxlength="30"
                     v-stringLimit
                     class="input"></Input>
              <span>分钟</span>
            </div>
            <p class="li-title">设置后，平台会在工单逾期前{{timeout}}分钟推送给相关人员</p>
          </div>
        </template>
        <template v-if="workorderIndex===4">
          <div class="title">
            <Icon custom="i-icon icon-jingshi"
                  size="16"
                  color='rgb(255, 149, 0)'></Icon>
            <p class="li-title">编辑问题来源</p>
          </div>
          <div class="source">
            <Table ref="table"
                   height="410"
                   :columns="columnsSource"
                   :data="sourceData"
                   border
                   stripe>
              <template slot-scope="{ row,index }"
                        slot="operation">
                <Button @click.stop="onClickDeleteQuestSource(row,index)"
                        class="tableOperaBtn"
                        type="text">删除</Button>
              </template>
            </Table>
            <Button type="text"
                    style="padding-left:0"
                    @click.stop="onClickAddQuestSoure">
              <Icon custom="i-icon icon-tianjiabiankuang"
                    size="16" />
              新增
            </Button>
          </div>
        </template>
        <template v-if="workorderIndex===5">
          <div class="title">
            <Icon custom="i-icon icon-jingshi"
                  size="16"
                  color='rgb(255, 149, 0)'></Icon>
            <p class="li-title">设置自动指派半径后，可在事件地点为中心形成此半径区域作为判断。</p>
          </div>
          <div class="cont-input">
            <p class="li-title">指派半径：</p>
            <Input v-model="radius"
                   placeholder="请输入指派半径"
                   maxlength="30"
                   v-intLimit
                   class="input"></Input>
            <span>m</span>
          </div>
        </template>
      </div>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '工单设置'
    },
    visible: Boolean,
  },
  data () {
    return {
      loading: false,
      // gradeArr: [],
      isImgYxxcsc: false,
      imgsize: '',
      isVideoYxxcsc: false,
      videolength: '',
      videosize: '',
      voicelength: '',
      voicesize: '',
      isTimeoutAlarm: false,
      timeout: '',
      radius: '',
      workorderArr: [
        {
          name: '等级与限时'
        },
        {
          name: '上报设置'
        },
        {
          name: '问题类型'
        },
        {
          name: '逾期提醒'
        },
        {
          name: '问题来源'
        },
        {
          name: '指派半径'
        }
      ],
      workorderIndex: 0,
      // questTypeData: [],
      questTypeIndex: 0,
      columnsType: [
        {
          title: '问题类型',
          width: 290,
          key: 'name',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let index = params.index
            return h('Input', {
              props: {
                value: row.name
              },
              style: {
                width: '100px'
              },
              on: {
                'on-change': (event) => {
                  this.typeData[index].name = event.target.value
                }
              }
            })
          }
        },
        {
          title: '启用状态',
          width: 100,
          key: 'isEnable',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let index = params.index
            return h('Checkbox', {
              props: {
                value: row.isenable == 1 ? true : false
              },
              on: {
                'on-change': (event) => {
                  this.typeData[index].isenable = event ? 1 : 0
                }
              }
            })
          }
        },
        {
          title: '操作',
          width: 100,
          slot: 'operation',
          align: 'center'
        }
      ],
      columnsSource: [
        {
          title: '问题来源',
          key: 'name',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let index = params.index
            return h('Input', {
              props: {
                value: row.name,
                placeholder: '请填写来源'
              },
              style: {
                width: '100px'
              },
              on: {
                'on-change': (event) => {
                  this.sourceData[index].name = event.target.value
                }
              }
            })
          }
        },
        {
          title: '排序',
          key: 'sort',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let index = params.index
            return h('Input', {
              props: {
                value: row.sort,
                placeholder: '请填写序列'
              },
              style: {
                width: '100px'
              },
              on: {
                'on-change': (event) => {
                  this.sourceData[index].sort = event.target.value
                }
              }
            })
          }
        },
        {
          title: '启用状态',
          key: 'isEnable',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let index = params.index
            return h('Checkbox', {
              props: {
                value: row.isEnable == 1 ? true : false
              },
              on: {
                'on-change': (event) => {
                  this.sourceData[index].isEnable = event ? 1 : 0
                }
              }
            })
          }
        },
        {
          title: '操作',
          slot: 'operation',
          align: 'center'
        }
      ],
      typeData: [],
      sourceData: [],
      tableLoading: false,
    }
  },
  watch: {
    visible (newVal) {
      if (newVal) {
        // this.getReportedData()
        // this.getQuestTypeList()
        // this.getQuestSourceList()
      }
    },
    questTypeIndex (newVal) {
      if (this.questTypeData[newVal].children && this.questTypeData[newVal].children.length != 0) {
        this.typeData = this.questTypeData[newVal].children
      } else {
        this.typeData = this.questTypeData[newVal].children = []
      }
    },
    workorderIndex (newVal) {
      switch (newVal) {
        case 0:
          this.updateWorkOrderLevelAllList(true)
          break;
        case 1:
          this.getReportedData()
          break;
        case 2:
          this.updateWorkOrderQuestTypeTreeList(true)
          break;
        case 3:
          this.getReportedData()
          break;
        case 4:
          this.getQuestSourceList()
          break;
        case 5:
          this.getReportedData()
          break;
        default:
          break;
      }
    }
  },
  computed: {
    ...mapGetters({
      'gradeArr': 'getWorkOrderLevelAllList',
    }),
    questTypeData () {
      let arr = []
      arr = this.$store.getters.getWorkOrderQuestTypeTreeList
      if (arr[this.questTypeIndex].children) {
        this.typeData = arr[this.questTypeIndex].children
      } else {
        this.typeData = arr[this.questTypeIndex].children = []
      }
      return arr
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateWorkOrderLevelAllList',
      'updateWorkOrderQuestTypeTreeList'
    ]),
    init () {
      // patrolCase已调用，防重复调用
      // this.updateWorkOrderLevelAllList()
      // this.updateWorkOrderQuestTypeTreeList()
    },
    // 切换选项
    onClickWorkorder (i) {
      this.workorderIndex = i
    },
    // 切换问题大类
    onClickQuestType (i) {
      this.questTypeIndex = i
    },
    // 新增大类
    onClickAddBigType () {
      this.questTypeData.push({
        name: '',
        isEnable: 1,
        isEdit: true,
        id: ''
      })
    },
    // 修改问题大类
    onClickEditBigType (item) {
      item.editName = item.name
      item.isEdit = true
      this.$forceUpdate()
    },
    // 删除问题大类
    onClickDeleteBigType (item) {
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            casesetIssueId: item.id
          }
          this.$http.deleteWorkOrderQuestType(params).then(res => {
            if (res.code === 200) {
              this.$Message.info(res.message)
              // this.getQuestTypeList()
              this.updateWorkOrderQuestTypeTreeList(true)
              this.questTypeIndex = 0
            }
          })
        }
      })
    },
    // 确定 新增 / 修改 问题大类 
    onClickBigTypeConFirm (item) {
      if (!item.editName) return this.$Message.info({ content: '请输入问题类型' })
      item.name = item.editName
      item.isEdit = false
      let params = {
        name: item.name,
        isenable: item.isenable
      }
      if (item.id) {
        params.casesetIssueId = item.id
        this.$http.editWorkOrderQuestType(params).then((res) => {
          this.$Message.info(res.message)
          // this.getQuestTypeList()
          this.updateWorkOrderQuestTypeTreeList(true)
        })
      } else {
        this.$http.createdWorkOrderQuestType(params).then((res) => {
          this.$Message.info(res.message)
          // this.getQuestTypeList()
          this.updateWorkOrderQuestTypeTreeList(true)
        })
      }
    },
    // 取消 新增 / 修改 问题大类
    onClickBigTypeCancel (item, index) {
      if (item.id) {
        item.isEdit = false
      } else {
        this.questTypeData.splice(index, 1)
      }
      this.$forceUpdate()
    },
    // 新增问题小类
    onClickAddSmallType () {
      this.questTypeData[this.questTypeIndex].children.unshift({
        name: '',
        isenable: 1,
        parentid: this.questTypeData[this.questTypeIndex].id
      })
    },
    // 删除问题小类
    onClickDeleteSmallType (row, index) {
      if (row.id) {
        this.$Modal.confirm({
          title: '删除',
          content: '确认删除?',
          onOk: () => {
            let params = {
              casesetIssueId: row.id
            }
            this.$http.deleteWorkOrderQuestType(params).then(res => {
              if (res.code === 200) {
                this.$Message.info(res.message)
                // this.getQuestTypeList()
                this.updateWorkOrderQuestTypeTreeList(true)
              }
            })
          }
        })
      } else {
        this.typeData.splice(index, 1)
      }
    },
    // 添加问题来源
    onClickAddQuestSoure () {
      this.sourceData.unshift({
        name: '',
        sort: '',
        isEnable: 1
      })
    },
    // 删除问题来源
    onClickDeleteQuestSource (row, index) {
      if (row.casesetIssueSourceId) {
        this.$Modal.confirm({
          title: '删除',
          content: '确认删除?',
          onOk: () => {
            let params = {
              casesetIssueSourceId: row.casesetIssueSourceId
            }
            this.$http.deleteWorkOrderQuestSource(params).then(res => {
              if (res.code === 200) {
                this.$Message.info(res.message)
                this.getQuestSourceList()
              }
            })
          }
        })
      } else {
        this.sourceData.splice(index, 1)
      }
    },
    // 确定按钮
    onClickConfirm () {
      let issue = []
      this.questTypeData.forEach(item => {
        if (item.children) {
          item.children.forEach(items => {
            let obj = {
              name: items.name,
              parentid: items.parentid,
              isenable: items.isenable,
            }
            if (items.id) {
              // 有id的是修改
              obj.casesetIssueId = items.id
            }
            if (obj.name) {
              issue.push(obj)
            }
          })
        }
      })
      // 上报设置，逾期提醒，指派半径
      let sys = {
        imgsize: this.imgsize,
        isImgYxxcsc: this.isImgYxxcsc,
        videosize: this.videosize,
        videolength: this.videolength,
        isVideoYxxcsc: this.isVideoYxxcsc,
        voicelength: this.voicelength,
        voicesize: this.voicesize,
        isTimeoutAlarm: this.isTimeoutAlarm,
        timeout: this.timeout,
        radius: this.radius
      }
      // level：saveOrUpdateBatchCasesetLevel 等级与限时
      // issue：saveOrUpdateBatchCasesetIssue 问题类型 2
      // issuesource：saveOrUpdateBatchCasesetIssueSource 问题来源
      // sys：casesetSysUpdateById 上报设置，逾期提醒，指派半径
      let params = {
        issue: issue,
        issuesource: this.sourceData,
        level: this.gradeArr,
        sys: sys,
      }
      this.loading = true
      switch (this.workorderIndex) {
        case 0:
          this.$http.editWorkOrderLevel(params.level).then((res) => {
            this.updateWorkOrderLevelAllList(true)
            this.$Message.info(res.message)
            this.$emit('onClickConfirm', params)
            this.$emit('onChange', false)
            this.loading = false
          }).catch(err => {
            this.loading = false
          })
          break;
        case 1:
          this.$http.editWorkOrderSys(params.sys).then((res) => {
            this.$Message.info(res.message)
            this.$emit('onClickConfirm', params)
            this.$emit('onChange', false)
            this.loading = false
          }).catch(err => {
            this.loading = false
          })
          break;
        case 2:
          this.$http.editWorkOrderIssue(params.issue).then((res) => {
            this.updateWorkOrderQuestTypeTreeList(true)
            this.$Message.info(res.message)
            this.$emit('onClickConfirm', params)
            this.$emit('onChange', false)
            this.loading = false
          }).catch(err => {
            this.loading = false
          })
          break;
        case 3:
          this.$http.editWorkOrderSys(params.sys).then((res) => {
            this.$Message.info(res.message)
            this.$emit('onClickConfirm', params)
            this.$emit('onChange', false)
            this.loading = false
          }).catch(err => {
            this.loading = false
          })
          break;
        case 4:
          this.$http.editWorkOrderIssuesource(params.issuesource).then((res) => {
            this.$Message.info(res.message)
            this.$emit('onClickConfirm', params)
            this.$emit('onChange', false)
            this.loading = false
          }).catch(err => {
            this.loading = false
          })
          break;
        case 5:
          this.$http.editWorkOrderSys(params.sys).then((res) => {
            this.$Message.info(res.message)
            this.$emit('onClickConfirm', params)
            this.$emit('onChange', false)
            this.loading = false
          }).catch(err => {
            this.loading = false
          })
          break;
        default:
          break;
      }

      // this.$http.editWorkOrderLevel(params).then((res) => {
      //   this.$http.editWorkOrderIssue(params).then((res) => {
      //     this.$http.editWorkOrderIssuesource(params).then((res) => {
      //       this.$http.editWorkOrderSys(params).then((res) => {
      //         this.updateWorkOrderLevelAllList(true)
      //         this.updateWorkOrderQuestTypeTreeList(true)
      //         this.$Message.info(res.message)
      //         this.$emit('onClickConfirm', params)
      //         this.$emit('onChange', false)
      //         this.loading = false
      //       })
      //     })
      //   })
      // }).catch(err => {
      //   this.loading = false
      // })

      // this.$http.editWorkOrder(params).then((res) => {
      //   this.updateWorkOrderLevelAllList(true)
      //   this.updateWorkOrderQuestTypeTreeList(true)
      //   this.$Message.info(res.message)
      //   this.$emit('onClickConfirm', params)
      //   this.$emit('onChange', false)
      //   this.loading = false
      // }).catch(err => {
      //   this.loading = false
      // })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.workorderIndex = 0
        this.questTypeIndex = 0
      }
    },
    // 获取上报设置、逾期提醒、指派半径
    getReportedData () {
      this.$http.getWorkOrderReported().then((res) => {
        for (const i in res.result) {
          this[i] = res.result[i];
        }
        this.isImgYxxcsc = res.result.isImgYxxcsc == 1 ? true : false
        this.isVideoYxxcsc = res.result.isVideoYxxcsc == 1 ? true : false
        this.isTimeoutAlarm = res.result.isTimeoutAlarm == 1 ? true : false
      })
    },
    // 获取问题类型
    getQuestTypeList () {
      return
      this.tableLoading = true
      this.$http.getWorkOrderQuestTypeList().then((res) => {
        this.questTypeData = res.result.map(item => {
          item.isEdit = false
          return item
        })
        if (this.questTypeData[this.questTypeIndex].children) {
          this.typeData = this.questTypeData[this.questTypeIndex].children
        } else {
          this.typeData = this.questTypeData[this.questTypeIndex].children = []
        }
        this.tableLoading = false
      })
    },
    // 获取问题来源
    getQuestSourceList () {
      this.tableLoading = true
      this.$http.getWorkOrderQuestSourceList().then((res) => {
        this.sourceData = res.result
        this.tableLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-cont {
  max-height: none;
}
.modal {
  ::v-deep {
    .ivu-modal-header {
      background-color: rgb(245, 245, 245);
      border-radius: 6px;
    }
    .ivu-modal-body {
      padding: 0;
    }
  }
}
.workorder {
  display: flex;
  .workorder-left {
    min-width: 110px;
    background-color: rgb(245, 245, 245);
    border-bottom-left-radius: 6px;
    li {
      line-height: 39px;
      text-align: center;
      padding: 3px 2px;
      font-size: 13px;
      cursor: pointer;
    }
    .current-workorder {
      color: #25bb96;
    }
  }
  .workorder-right {
    height: 490px;
    flex: 1;
    padding: 8px;
    .title {
      display: flex;
      align-items: center;
      height: 30px;
      background: rgb(243, 243, 243);
      padding: 0px 10px;
      color: rgb(50, 45, 66);
      margin-bottom: 10px;
      p {
        margin-left: 5px;
      }
    }
    .cont-switch {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      ::v-deep .ivu-switch {
        margin-top: 4px;
      }
    }
    .cont-input {
      display: flex;
      line-height: 32px;
      margin-bottom: 10px;
      .input {
        width: 120px;
        margin-right: 5px;
      }
    }
    .cont-input:last-child {
      margin-bottom: 0;
    }
    .reported {
      .reported-item {
        .item-title {
          height: 30px;
          line-height: 30px;
          border-bottom: 1px dashed #ddd;
          margin-bottom: 5px;
        }
      }
    }
    .quest {
      height: 100%;
      .quest-big {
        width: 180px;
        height: 100%;
        ul {
          background-color: rgb(245, 245, 245);
        }
        li {
          line-height: 39px;
          padding: 3px 5px;
          font-size: 13px;
          cursor: pointer;
        }
        .current-type {
          color: #25bb96;
        }
      }
    }
  }
}
</style>