<template>
  <Modal :value="visible"
         :title="title"
         @on-visible-change="onChangeVisible"
         width="600"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <!-- 归档选择 -->
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>选择月份：</p>
          <DatePicker type="month"
                      placeholder=""
                      class="harf"
                      v-model="archiveTime"></DatePicker>
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '批量归档'
    },
    visible: Boolean,
  },
  data () {
    return {
      archiveTime: ''
    }
  },
  methods: {
    // 确定按钮
    onClickConfirm () {

    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-minheight {
  height: 300px;
}
.form-ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  li {
    width: 100%;
  }
  .half {
    width: 48%;
    margin-right: 2%;
  }
}
</style>