<template>
  <Drawer :title="title"
          closable
          width="40"
          :value="visible"
          @on-visible-change="onChangeVisible">
    <div class="detail"
         v-if="data">
      <div class="detail-top">
        <span class="detail-top-status dispatch">{{data.status==1?'待分派':data.status==2?'待处理':data.status==3?'待结案':data.status==4?'已结案':'已废弃'}}</span>
        <!-- <span class="detail-top-status2">催</span> -->
        <p>工单号：{{data.workOrderNumber}}
          <span class="overtime"
                v-if="data.isOverdue==1">已逾期</span>
        </p>
        <p class="detail-top-type"><span>{{data.questBigTypeName}}</span>【{{data.questSmallTypeName}}】</p>
        <p class="detail-top-item">
          <label>问题描述：</label><span>{{data.desc || '--'}}</span>
        </p>
        <p class="detail-top-item">
          <label>上报时间：</label><span>{{data.createTime || '--'}}</span>
        </p>
        <div>
          <el-image :src="data.picture[0]"
                    fit="cover"
                    :preview-src-list="[data.picture]"
                    class="detail-top-img"
                    v-if="data.picture">
          </el-image>
          <span v-else>暂无多媒体文件</span>
        </div>
        <p>
          <Icon custom="i-icon icon-dingwei"
                size="16"
                color="#000" />
          <span>{{data.site}}</span>
        </p>
      </div>
      <Tabs v-model="currentTabIndex">
        <TabPane :label="item.name"
                 v-for="(item,index) in tabsArr"
                 :key="index"></TabPane>
      </Tabs>
      <div v-show="currentTabIndex === 0">
        <div class="section">
          <div class="section-title">
            <span>上报信息</span>
          </div>
          <ul class="section-cont">
            <li>
              <label>上报人:</label>
              <span>{{data.createName || '--'}}</span>
            </li>
            <li>
              <label>问题等级:</label>
              <span>{{data.levelName || '--'}}</span>
            </li>
            <li>
              <label>处理限时:</label>
              <span>{{data.time+'分钟' || '--'}}</span>
            </li>
            <li>
              <label>抄送:</label>
              <span>{{data.CCName || '--'}}</span>
            </li>
            <li>
              <label>短信推送:</label>
              <span>{{data.sendSms?'开启':'关闭'}}</span>
            </li>
          </ul>
        </div>
        <div class="section"
             v-if="data.manageLastData">
          <div class="section-title">
            <span>处理信息</span>
            <span class="disposetimes"
                  v-if="data.manageLastData.manageCount>1">{{data.manageLastData.manageCount}}次处理</span>
          </div>
          <ul class="section-cont">
            <li>
              <label>责任人:</label>
              <span>{{data.manageLastData.createAccount || '--'}}</span>
            </li>
            <li>
              <label>处理时间:</label>
              <span>{{data.manageLastData.createTime || '--'}}</span>
            </li>
            <li class="one-row">
              <label>处理备注:</label>
              <span>{{data.manageLastData.remark || '--'}}</span>
            </li>
            <li class="one-row flex a-statr">
              <label>处理图片:</label>
              <el-image :src="data.laterPicture[0]"
                        fit="cover"
                        :preview-src-list="data.laterPicture"
                        v-if="data.laterPicture"
                        style="width:170px">
              </el-image>
              <span v-else>暂无多媒体文件</span>
            </li>
          </ul>
        </div>
      </div>

      <div v-show="currentTabIndex === 1">
        <div class="section">
          <div class="section-title">
            <span>处理时间轴</span>
            <span>累计耗时<span>{{data.consumingTime}}</span></span>
          </div>
          <div class="time-line">
            <Timeline>
              <TimelineItem color="green"
                            v-for="item,index in data.manageData"
                            :key="index">
                <!-- 时间轴点插槽 -->
                <div slot="dot"
                     class="time-line-dot">
                  <span class="time-line-dot-icon">
                    <Icon :custom="'i-icon '+timelineIcon(item.handle)"
                          size="24"
                          color="#fff" />
                  </span>
                  <span class="time-line-text">{{item.handle}}</span>
                </div>
                <!-- 内容 -->
                <div class="time-line-cont">
                  <div class="time-line-cont-row flex"
                       v-if="item.handle!=='创建'">
                    <p>
                      <Icon custom="i-icon icon-haoshi1"
                            size="16"
                            color="#000"
                            class="time-line-cont-row-icon" />
                      <label>耗时</label>
                      <span>{{item.costtimedetail}}</span>
                    </p>
                  </div>
                  <div class="time-line-cont-row flex">
                    <p><label>{{item.handle==='创建'?'上报':item.handle}}人：</label><span>{{item.createAccount}}</span></p>
                    <p><label>{{item.handle==='创建'?'上报':item.handle}}时间：</label><span>{{item.createTime}}</span></p>
                  </div>
                  <div class="time-line-cont-cont">
                    <template v-if="item.handle==='创建'||item.handle==='结案'">
                      <p>
                        <label>备注：</label><span>{{item.remark || '--'}}</span>
                      </p>
                    </template>
                    <template v-if="item.handle==='分派'||item.handle==='改派'">
                      <p>
                        <label>责任人：</label><span>{{item.personName || '--'}}</span>
                      </p>
                      <p>
                        <label>责任部门：</label><span>{{item.depName || '--'}}</span>
                      </p>
                      <p>
                        <label>问题等级：</label><span class="m-r-10">{{item.caseLevelName || '--'}}</span>
                        <label>限时：</label><span>{{item.timelimit+'分钟' || '--'}}</span>
                      </p>
                      <p>
                        <label>抄送人：</label><span>{{item.ccName || '--'}}</span>
                      </p>
                      <p>
                        <label>短信推送：</label><span>{{item.isSendSms?'开启':'关闭'}}</span>
                      </p>
                      <p>
                        <label>备注：</label><span>{{item.remark || '--'}}</span>
                      </p>
                    </template>
                    <template v-if="item.handle==='签到'">
                      <p>
                        <label>签到位置：</label><span>{{item.address || '--'}}</span>
                      </p>
                    </template>
                    <template v-if="item.handle==='处理'">
                      <el-image style="width:170px;"
                                :src="item.imgArr[0]"
                                fit="cover"
                                :preview-src-list="item.imgArr"
                                v-if="item.imgUrls">
                      </el-image>
                      <p>
                        <label>处理描述：</label><span>{{item.remark || '--'}}</span>
                      </p>
                    </template>
                  </div>
                </div>
              </TimelineItem>
            </Timeline>
          </div>
        </div>
      </div>

      <!-- <div v-show="currentTabIndex == 2">
        <div class="section">
          <div class="section-title">
            <span>读取记录</span>
          </div>
          <Table ref="table"
                 :columns="columns"
                 :data="data.police"
                 border
                 stripe
                 :loading="tableLoading">
          </Table>
        </div>
      </div> -->

      <div v-show="currentTabIndex === 2">
        <div class="section">
          <div class="section-title">
            <span>催办记录</span>
          </div>
          <Table ref="table"
                 :columns="columnsUrge"
                 :data="data.urgeData"
                 border
                 stripe>
          </Table>
        </div>
      </div>

      <!-- <div v-show="currentTabIndex == 4">
        <div class="section">
          <div class="section-title">
            <span>申请延期记录</span>
          </div>
          <div>
            <div class="noData">
              <img src="../../../../../assets/common/biaogeno.png">
              <p>暂无数据</p>
            </div>
          </div>
        </div>
      </div> -->

      <div v-show="currentTabIndex === 3">
        <div class="section">
          <div class="section-title">
            <span>恢复记录</span>
          </div>
          <div class="time-line">
            <Timeline>
              <TimelineItem color="green"
                            v-for="item,index in data.renewData"
                            :key="index">
                <!-- 时间轴点插槽 -->
                <div slot="dot"
                     class="time-line-dot">
                  <span class="time-line-dot-icon">
                    <Icon :custom="'i-icon '+timelineIcon(item.handle)"
                          size="24"
                          color="#fff" />
                  </span>
                  <span class="time-line-text">{{item.handle}}</span>
                </div>
                <!-- 内容 -->
                <div class="time-line-cont" style="margin-top: -10px;">
                  <div class="time-line-cont-row">
                    <p><label>{{item.handle}}时间：</label><span>{{item.createTime}}</span></p>
                    <p><label>{{item.handle}}人：</label><span>{{item.createAccount}}</span></p>
                  </div>
                </div>
              </TimelineItem>
            </Timeline>
          </div>
        </div>
      </div>

    </div>
  </Drawer>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '问题详情'
    },
    dataId: String
  },
  data () {
    return {
      currentTabIndex: 0,
      tabsArr: [
        {
          name: '处理结果',
        },
        {
          name: '处理详情',
        },
        // {
        //   name: '读取记录',
        // },
        {
          name: '催办记录',
        },
        // {
        //   name: '申请延期',
        // },
        {
          name: '恢复记录',
        }
      ],
      data: {},
      columnsUrge: [
        {
          title: '催办人员',
          key: 'createAccount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '催办时间',
          key: 'createTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属部门',
          key: 'depName',
          tooltip: true,
          align: 'center'
        }
      ]
    };
  },
  watch: {
    dataId (newValue) {
      newValue && this.getDetail()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    getDetail () {
      this.$store.dispatch('getPatrolCaseDetail', this.dataId).then(res => {
        this.data = res
        if (res.picture) {
          this.data.picture = res.picture.split(',').filter(item => item)
        }
        if (res.laterPicture) {
          this.data.laterPicture = res.laterPicture.split(',').filter(item => item)
        }
        // 处理信息
        let manageArr = res.caseProcesses.filter(item => item.handle === '处理')
        if (manageArr.length) {
          this.data.manageLastData = manageArr[manageArr.length - 1]
          this.data.manageLastData.manageCount = manageArr.length
        }
        // 处理记录
        this.data.manageData = res.caseProcesses.filter(item => item.handle !== '催办' && item.handle !== '恢复' && item.handle !== '废弃')
        this.data.manageData.forEach(item => {
          if (item.imgUrls) {
            item.imgArr = item.imgUrls.split(',').filter(items => items)
          }
        })
        // 催办记录
        this.data.urgeData = res.caseProcesses.filter(item => item.handle === '催办')
        // 恢复记录
        this.data.renewData = res.caseProcesses.filter(item => item.handle === '恢复' || item.handle === '废弃')
      })
    },
    // 时间轴icon
    timelineIcon (status) {
      let icon = ''
      switch (status) {
        case '创建':
          icon = 'icon-tianjiabiankuang'
          break;
        case '分派':
          icon = 'icon-jiaose'
          break;
        case '改派':
          icon = 'icon-jiaohuan1'
          break;
        case '签到':
          icon = 'icon-dingwei'
          break;
        case '处理':
          icon = 'icon-kaohe'
          break;
        case '结案':
          icon = 'icon-wancheng'
          break;
        case '废弃':
          icon = 'icon-jinzhi'
          break;
        case '恢复':
          icon = 'icon-wanchengtishi'
          break;
        default:
          break;
      }
      return icon
    },
    onChangeVisible (boo) {
      !boo && this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    },
    resetData () {
      this.currentTabIndex = 0
      this.data = {}
    }
  },
}
</script>

<style lang='scss' scoped>
.detail {
  .detail-top {
    padding: 8px 4px 10px 44px;
    position: relative;
    box-sizing: border-box;
    .detail-top-status {
      position: absolute;
      display: block;
      top: 12px;
      left: -26px;
      padding: 3px 10px 3px 15px;
      border-radius: 30px;
      color: #fff;
      font-size: 12px;
    }
    // 待分派
    .dispatch {
      background: #ff3b30;
    }
    // 待处理
    .pending {
      background: #fe9400;
    }
    // 已结案
    .caseclosed {
      background: #0eb36e;
    }
    .detail-top-status2 {
      position: absolute;
      top: -16px;
      right: 30px;
      width: 26px;
      height: 29px;
      background: #ff3b30;
      color: #fff;
      text-align: center;
      font-size: 12px;
    }
    .detail-top-status2::before {
      content: "";
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 11px solid transparent;
      border-bottom: 7px solid #fff;
      position: absolute;
      bottom: 0;
      left: 1px;
    }
    p {
      line-height: 30px;
      color: #333;
      .overtime {
        border: 1px solid #797979;
        background: #f2f2f2;
        border-radius: 3px;
        padding: 2px 5px;
        font-size: 12px;
      }
    }
    .detail-top-type {
      span {
        font-size: 18px;
      }
    }
    .detail-top-img {
      width: 170px;
      height: 108px;
    }
  }
  .section {
    .section-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      border-bottom: 1px solid #ddd;
      margin-bottom: 10px;

      span:first-child {
        font-weight: 700;
        // font-size: 16px;
        padding-left: 10px;
        border-left: 2px solid #25bb96;
      }
      span:last-child {
        span {
          border: none;
          color: #0079fe;
        }
      }
      .disposetimes {
        margin-left: 5px;
        border: 1px solid #797979;
        background: #f2f2f2;
        border-radius: 3px;
        padding: 2px 5px;
        font-size: 12px;
      }
    }
    .section-cont {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 15px;
      li {
        width: 50%;
        line-height: 34px;
        label {
          display: inline-block;
          min-width: 100px;
          text-align: right;
          margin-right: 10px;
        }
      }
      .one-row {
        width: 100%;
      }
      .img-row {
        display: flex;
        align-items: flex-start;
        img {
          width: 50%;
          margin-right: 10px;
        }
        span {
          margin-right: 10px;
        }
      }
    }
    .noData {
      p {
        color: #000;
      }
    }
  }
}

.time-line {
  padding-left: 60px;
  padding-top: 20px;
  .time-line-dot {
    position: relative;
    .time-line-dot-icon {
      display: inline-block;
      width: 40px;
      height: 40px;
      background: #027ef9;
      color: #fff;
      text-align: center;
      line-height: 42px;
      border-radius: 50%;
    }
    .time-line-text {
      position: absolute;
      left: -50px;
      top: 50%;
      transform: translate(0px, -50%);
      font-size: 18px;
      color: #333;
      width: 40px;
    }
  }
  .time-line-cont {
    padding-left: 10px;
    .time-line-cont-row {
      // display: flex;
      .time-line-cont-row-icon {
        margin-right: 5px;
      }
      margin-bottom: 10px;
      p {
        margin-right: 20px;
      }
    }
    .time-line-cont-cont {
      background: #f9f9f9;
      width: 100%;
      padding: 3px;
      p {
        line-height: 24px;
        padding-left: 5px;
        word-break: break-all;
        label {
          color: #6a6b75;
        }
        span {
          color: #333642;
        }
        img {
          width: 99px;
          height: 80px;
          margin-right: 7px;
          margin-bottom: 7px;
        }
      }
    }
  }
}
</style>